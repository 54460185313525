<template>
  <div class="container">
    <el-swiper :src='imgSrc'
               :info='info'></el-swiper>
    <div class="content1">
      <div class="main">
        <div class="part part1">
          <p class="title">江苏尚盈期颐信息网络有限公司</p>
          <div class="bgimg"></div>
          <div class="info_">
            <p class="tent1">江苏中盈高科智能信息股份有限公司</p>
            <p class="tent2">（股票代码：837274）</p>
            <p class="tent3">旗下全资子公司</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="main">
        <div class="part2">
          <div class="dsc">江苏尚盈期颐信息网络有限公司（简称“尚盈期颐”），成立于2017年12月，注册资本1000万元。是江苏中盈高科智能信息股份有限公司（股票代码：837274）的全资子公司。</div>
          <div class="dsc">尚盈期颐专注城市静态交通领域，提供城市停车服务、关联业态信息化建设、数字化管理运营等服务。通过对停车场AI智能设备、物联网设备控制、上云接入、数字化运营系统的开发，构建以企业信息化建设、企业数字经营为主体，以实施规划设计、实施安装、运行运维、市场营销等相结合的数字经济一体化生态圈服务体系。
          </div>
          <div class="dsc">综合运用互联网、云服务、物联网、大数据分析处理等先进信息技术，摸索建立了可持续运营的商业服务模式：面向各类停车场、车主业主、物业公司、零售门店实现跨区域、多层级的管理和服务，为政府、主管部门、运营企业和消费者提供运营监管、经营提升、福利优惠、营销推广等各类服务。</div>
          <div class="dsc">公司长期致力于科技创新与产品研发，凭借自身技术实力，自主研发配套产品或衍生增值服务，提供SAAS服务输出、PAAS服务输出。</div>
          <div class="pics">
            <div class="pic1">
              <img class="img_"
                   src="../assets/images/company_part2_1.png"
                   alt="">
            </div>
            <div class="pic2">
              <img class="img_"
                   src="../assets/images/company_02.jpg"
                   alt="">
            </div>
            <div class="pic3">
              <img class="img_"
                   src="../assets/images/company_part2_3.png"
                   alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content3">
      <div class="main">
        <div class="part3">
          <div class="title">企业文化</div>
          <div class="bgimg"></div>
          <div class="info_">
            <p class="tent1">企业宗旨：科学合理地创造社会价值和经济价值</p>
            <p class="tent2">企业精神：诚信高效 务实创新 健康和谐 追求卓越</p>
            <p class="tent3">经营理念：传递智能价值 创新盈领未来</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content4">
      <div class="main">
        <div class="part4">
          <div class="title">荣誉资质</div>
          <div class="bgimg"></div>
          <div class="pics pics1">
            <div class="pic">
              <el-image class="img_"
                        :src="url4_1"
                        alt=""
                        lazy></el-image>
              <!-- <img src="../assets/images/company_part4_1.jpg"
                   alt=""> -->
              <p>实用新型专利证书</p>
            </div>
            <div class="pic">
              <el-image class="img_"
                        :src="url4_2"
                        alt=""
                        lazy></el-image>
              <!-- <img src="../assets/images/company_part4_2.jpg"
                   alt=""> -->
              <p>软件著作权证书</p>
            </div>
            <div class="pic">
              <el-image class="img_"
                        :src="url4_3"
                        alt=""
                        lazy></el-image>
              <!-- <img src="../assets/images/company_part4_3.jpg"
                   alt=""> -->
              <p>ISO 9001体系认证证书</p>
            </div>
          </div>
          <div class="pics pics2">
            <div class="pic">
              <el-image class="img_"
                        :src="url4_4"
                        alt=""
                        lazy></el-image>
              <!-- <img src="../assets/images/company_part4_4.jpg"
                   alt=""> -->
              <p>民营科技企业证书</p>
            </div>
            <div class="pic">
              <el-image class="img_"
                        :src="url4_5"
                        alt=""
                        lazy></el-image>
              <!-- <img src="../assets/images/company_part4_5.jpg"
                   alt=""> -->
              <p>创新创业大赛三等奖</p>
            </div>
            <div class="pic">
              <el-image class="img_"
                        :src="url4_6"
                        alt=""
                        lazy></el-image>
              <p>AAA信用等级证书</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      imgSrc: require('@/assets/images/banner2.png'),
      info: {
        title: '关于我们',
        dsc: 'About us',
        number: ''
      },
      url4_1: require('@/assets/images/company_part4_1.jpg'),
      url4_2: require('@/assets/images/company_part4_2.jpg'),
      url4_3: require('@/assets/images/company_part4_3.jpg'),
      url4_4: require('@/assets/images/company_part4_4.jpg'),
      url4_5: require('@/assets/images/company_part4_5.jpg'),
      url4_6: require('@/assets/images/company_part4_6.jpg')
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
@import "@/assets/styles/company.scss";
</style>
